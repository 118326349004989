import * as s from '../../constants/stageNames';

export const order = [
  s.LOAN_AMOUNT_STAGE,
  s.LOAN_TERM_STAGE,
  s.LOAN_PURPOSE_STAGE,
  s.LOAN_PURPOSE_DETAIL_STAGE,
  s.TITLE_STAGE,
  s.NAME_STAGE,
  s.DOB_STAGE,
  s.EMAIL_STAGE,
  s.CONTACT_NUMBER_STAGE,
  s.MARITAL_STATUS_STAGE,
  s.NUMBER_OF_DEPENDANTS_STAGE,
  s.ADDRESS_STAGE,
  s.RESIDENTIAL_STATUS_STAGE,
  s.PROPERTY_VALUE_STAGE,
  s.TIME_AT_CURRENT_ADDRESS_STAGE,
  s.PREVIOUS_ADDRESS_STAGE,
  s.PREVIOUS_RESIDENTIAL_STATUS_STAGE,
  s.TIME_AT_PREVIOUS_ADDRESS_STAGE,
  s.EMPLOYMENT_STATUS_STAGE,
  s.EMPLOYER_INDUSTRY_STAGE,
  s.GROSS_SALARY_STAGE,
  s.EMPLOYER_NAME_STAGE,
  s.JOB_TITLE_STAGE,
  s.TIME_AT_CURRENT_JOB_STAGE,
  s.SALARY_FREQUENCY_STAGE,
  s.NEXT_PAY_DATE_STAGE,
  s.MONTHLY_TAKE_HOME_PAY_STAGE,
  s.INCOME_PAYMENT_TYPE_STAGE,
  s.EXPECTING_INCOME_CHANGE_STAGE,
  s.REASONS_FOR_CHANGE_TO_INCOME_STAGE,
  s.REASONS_FOR_CHANGE_TO_INCOME_DETAIL_STAGE,
  s.OTHER_MONTHLY_INCOME_STAGE,
  s.MONTHLY_EXPENSES_STAGE,
  s.MONTHLY_EXPENSES_OTHER_STAGE,
  s.DEBT_EXPENSES_STAGE,
  s.DEFAULTED_STAGE,
  s.CCJ_STAGE,
  s.IVA_STAGE,
  s.BANKRUPT_STAGE,
  s.BANK_DETAILS_STAGE,
  s.SUBMIT_STAGE,
];

export default order;

export function getStageIndex(stageName) {
  for (let i = 0; i < order.length; i += 1) {
    if (stageName === order[i]) return i;
  }

  console.error(`${stageName} not in stage list`);
}

export function getPercentageComplete(activeStage, hidden) {
  if (!activeStage) return 0;

  let before = 0;
  let after = 0;

  const stageIndex = getStageIndex(activeStage);

  for (let i = 0; i < order.length; i += 1) {
    if (!hidden[order[i]]) {
      if (i < stageIndex) {
        before += 1;
      } else {
        after += 1;
      }
    }
  }

  return parseInt((before / (after + before)) * 100);
}
