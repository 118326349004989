export const req = (type, payload) => {
  return {
    type,
    payload,
    meta: 'req',
  };
};

export const ok = (type, payload) => {
  return {
    type,
    payload,
  };
};

export const fail = (type, error, meta) => {
  return {
    type,
    payload: error,
    error: true,
    meta,
  };
};

export const isReq = action => action.meta === 'req';
export const isFail = action => action.error === true;
export const isOk = action => !isFail(action) && !isReq(action);
