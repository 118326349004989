import update from 'immutability-helper';
import * as R from 'rambda';

const { curry, compose } = R;

export const setBusy = curry((key, isBusy, state) => {
  return update(state, { [key]: { busy: { $set: isBusy } } });
});

export const setStateField = curry((statePart, field, value, state) => {
  return update(state, {
    [statePart]: {
      [field]: { $set: value },
    },
  });
});

export const mergeStateField = curry((statePart, obj, state) => {
  return update(state, {
    [statePart]: { $merge: obj },
  });
});

export const updateFormField = (field, value, valid, error, warning, state) => {
  return compose(
    setData(field, value),
    setValid(field, valid),
    setError(field, error)
    // setWarning(field, warning),
  )(state);
};

export const mergeAddresses = curry((statePart, obj, state) => {
  return update(state, {
    addresses: {
      [statePart]: { $merge: obj },
    },
  });
});

export const mergeCurrentAddress = mergeAddresses('current');
export const mergePrevAddress = mergeAddresses('prev');

export const setUI = setStateField('ui');
export const mergeUI = mergeStateField('ui');
export const setDate = setStateField('dates');
export const setNow = setStateField('dates', 'now');
export const setSetting = setStateField('settings');
export const setSubmitted = setStateField('submitted');
export const setHidden = setStateField('hidden');
export const mergeHidden = mergeStateField('hidden');

export const setData = setStateField('data');
export const mergeData = mergeStateField('data');
export const setWarning = setStateField('warnings');
export const setValid = setStateField('valid');
export const mergeValid = mergeStateField('valid');
export const setError = setStateField('errors');
export const mergeErrors = mergeStateField('errors');

export const hideModal = mergeUI({
  activeModal: null,
});
