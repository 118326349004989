import { compose } from 'rambda';
import update from 'immutability-helper';
import ReactGA from 'react-ga';
import * as t from './actionTypes';
import { saveForm, getForm } from '../components/form/saveLoad';
import initialState from './../state/initialState';
import getNextStage from './../components/form/getNextStage';
import validateStage from './../components/form/validateStage';
import stageLogic from './../components/form/stageLogic';
import fieldValidation from './../components/form/validation';
import * as u from './update';
import * as f from '../constants/fieldNames';
import * as ev from '../constants/analyticsEvents';
import { isReq, isOk, isFail } from '../util/req';
import { submitForm } from '../components/form/submitForm';
import { setAddress } from '../components/form/addresses';

const reducer = (currentState = initialState, action) => {
  const { type, payload } = action;
  let state = currentState;

  switch (type) {
    case t.UPDATE_FORM_FIELD: {
      const { field, value } = payload;

      const [ok, err] = fieldValidation[field](value, state);

      return u.updateFormField(field, value, ok, err, null, state);
    }
    case t.TOGGLE_BOOL_FIELD: {
      const field = payload.field;
      const value = !state.data[field];

      const [ok, err] = fieldValidation[field](value, state);

      return u.updateFormField(field, value, ok, err, null, state);
    }
    case t.UPDATE_NUMBER_FIELD: {
      let { field, value } = payload;

      value = value !== '' ? parseInt(value) : 0;

      if (isNaN(value)) {
        value = '';
      }

      const [ok, err] = fieldValidation[field](value, state);

      return u.updateFormField(field, value, ok, err, null, state);
    }
    case t.UPDATE_SELECT_BOOL_FIELD: {
      const field = payload.field;
      const value = payload.value === 'true';
      const [ok, err] = fieldValidation[field](value, state);

      return u.updateFormField(field, value, ok, err, null, state);
    }
    case t.SET_DOB_PART: {
      const { partName, value } = payload;

      const field = f.DOB;

      const dob = {
        ...state.data[f.DOB],
        [partName]: value,
      };

      const [ok, err] = fieldValidation[field](dob, state);

      return u.updateFormField(field, dob, ok, err, '', state);
    }
    case t.REQUEST_ADDRESSES: {
      if (isFail(action)) {
        const prev = action.meta;
        const merge = prev ? u.mergePrevAddress : u.mergeCurrentAddress;

        return merge(
          {
            busy: false,
            hasRun: true,
            error: 'Something has gone wrong',
          },
          state
        );
      }

      const merge = payload.prev ? u.mergePrevAddress : u.mergeCurrentAddress;

      if (isReq(action)) {
        return merge(
          {
            hasRun: true,
            busy: true,
          },
          state
        );
      }

      if (isOk(action)) {
        return merge(
          {
            busy: false,
            hasRun: true,
            data: payload.data,
          },
          state
        );
      }
      break;
    }
    case t.SELECT_ADDRESS: {
      return setAddress(payload.index, payload.prev, state);
    }
    case t.NEXT_STAGE: {
      // don't do anything if transitioning between stages. This is to stop
      // multiple quick clicks on select boxes skipping multiple stages
      if (state.ui.transitioning) return state;

      const [allValid, meta] = validateStage(state);

      const { activeStage } = state.ui;

      state = compose(
        u.mergeValid(meta.valid),
        u.mergeErrors(meta.errors)
      )(state);

      // default to staying on same stage in case stage validation fails
      let nextStage = activeStage;

      if (allValid) {
        const sl = stageLogic[activeStage];

        if (sl) {
          state = sl(state);
        }
        saveForm(state.data);
        nextStage = getNextStage(state, activeStage);

        ReactGA.event({
          category: ev.FORM_CATEGORY,
          action: ev.FORM_ACTION_ACTIVE_STAGE,
          label: nextStage,
        });
      }

      // only mark as transitioning if we are moving to a different stage
      const transitioning = nextStage !== activeStage;

      return compose(
        u.setSubmitted(activeStage, true),
        u.mergeUI({
          activeStage: nextStage,
          transitioning,
          direction: 'forwards',
        })
      )(state);
    }
    case t.PREV_STAGE: {
      const nextStage = getNextStage(state, state.ui.activeStage, true);

      return u.mergeUI(
        {
          activeStage: nextStage,
          direction: 'backwards',
          transitioning: true,
        },
        state
      );
    }
    case t.SET_STAGE: {
      const nextStage = payload.stageName;
      return compose(
        u.mergeUI({
          activeStage: nextStage,
          transitioning: true,
          direction: 'forwards',
        })
      )(state);
    }
    case t.SET_TIMER_STAGE: {
      return u.setUI('activeTimerStage', payload.stageName, state);
    }
    case t.STAGE_READY: {
      return u.setUI('transitioning', false, state);
    }
    case t.SUBMIT_FORM: {
      submitForm(state.data, state.settings);
      return u.setUI('formSending', true, state);
    }
    case t.LOAD_SAVED: {
      const valid = {};
      const form = {};
      const errors = {};
      const warnings = {};

      const localFormData = getForm();

      if (localFormData) {
        Object.keys(localFormData)
          .filter(field => field !== f.LOAN_AMOUNT || field !== f.LOAN_TERM)
          .forEach(field => {
            const value = localFormData[field];
            form[field] = value;

            if (fieldValidation[field]) {
              const [ok, err] = fieldValidation[field](value, state);

              valid[field] = ok;

              if (!ok) errors[field] = err;
            }
          });

        return update(state, {
          data: { $merge: form },
          valid: { $merge: valid },
          errors: { $merge: errors },
          warnings: { $merge: warnings },
        });
      }
      break;
    }
    case t.PERCENTAGE_COMPLETE: {
      return u.setUI('percentComplete', payload, state);
    }
    default:
  }

  return state;
};

export default reducer;
