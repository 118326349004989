// Please do not add anything to this file which is not a real clearchoice field
export const AFFILIATE_REF = 'affref'; 
export const LOAN_AMOUNT = 'loanAmount';
export const LOAN_TERM = 'loanTerm';
export const LOAN_PURPOSE = 'loanPurpose';
export const LOAN_PURPOSE_DETAIL = 'loanPurposeDetail';
export const TITLE = 'title';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const DOB = 'dob';
export const EMAIL_ADDRESS = 'emailAddress';
export const HOME_PHONE = 'homePhone';
export const MOBILE_PHONE = 'mobilePhone';
export const WORK_PHONE = 'workPhone';
export const MARITAL_STATUS = 'maritalStatus';
export const NUMBER_OF_DEPENDANTS = 'numberOfDependants';
export const OTHER_NUMBER_OF_DEPENDANTS = 'otherNumberOfDependants';
export const POSTCODE = 'postCode';
export const ADDRESS_1 = 'address1';
export const ADDRESS_2 = 'address2';
export const ADDRESS_3 = 'address3';
export const TOWN_OR_CITY = 'townOrCity';
export const COUNTY = 'county';
export const STREET = 'street';
export const FLAT_NAME_OR_NUMBER = 'flatNameOrNumber';
export const HOUSE_NUMBER = 'houseNumber';
export const HOUSE_OR_BUILDING_NAME = 'houseOrBuildingName';
export const RESIDENTIAL_STATUS = 'residentialStatus';
export const PROPERTY_VALUE = 'propertyValue';
export const TIME_AT_CURRENT_ADDRESS = 'timeAtCurrentAddress';
export const PREVIOUS_POSTCODE = 'previousPostCode';
export const PREVIOUS_ADDRESS_1 = 'previousAddress1';
export const PREVIOUS_ADDRESS_2 = 'previousAddress2';
export const PREVIOUS_ADDRESS_3 = 'previousAddress3';
export const PREVIOUS_TOWN_OR_CITY = 'previousTownOrCity';
export const PREVIOUS_COUNTY = 'previousCounty';
export const PREVIOUS_FLAT_NAME_OR_NUMBER = 'previousFlatNameOrNumber';
export const PREVIOUS_HOUSE_NUMBER = 'previousHouseNumber';
export const PREVIOUS_HOUSE_OR_BUILDING_NAME = 'previousHouseOrBuildingName';
export const PREVIOUS_STREET = 'previousStreet';
export const PREVIOUS_RESIDENTIAL_STATUS = 'previousResidentialStatus';
export const TIME_AT_PREVIOUS_ADDRESS = 'timeAtPreviousAddress';
export const EMPLOYMENT_STATUS = 'employmentStatus';
export const GROSS_SALARY = 'grossSalary';
export const COMPANY_NAME = 'companyName';
export const EMPLOYER_INDUSTRY = 'employerIndustry';
export const OCCUPATION = 'occupation';
export const TIME_AT_CURRENT_JOB = 'timeInCurrentJob';
export const SALARY_FREQUENCY = 'salaryFrequency';
export const NEXT_PAY_DATE = 'nextPayDate';
export const FOLLOWING_PAY_DATE = 'followingPayDate';
export const MONTHLY_TAKE_HOME_PAY = 'monthlyTakeHomePay';
export const EXPECTING_CHANGE_TO_INCOME = 'expectingChangeToIncome';
export const REASON_FOR_CHANGE_TO_INCOME = 'reasonForChangeToIncome';
export const REASON_FOR_CHANGE_TO_INCOME_DETAIL =
  'reasonForChangeToIncomeDetail';
export const OTHER_MONTHLY_INCOME = 'otherMonthlyIncome';
export const INCOME_PAYMENT_TYPE = 'incomePaymentType';
export const MONTHLY_MORTGAGE_OR_RENT = 'monthlyMortgageOrRent';
export const DEBT_EXPENSES = 'debtExpenses';
export const TOTAL_OUTSTANDING_DEBT = 'totalOutstandingDebt';
export const UTILITIES_EXPENSES = 'utilitiesExpenses';
export const TRANSPORT_EXPENSES = 'transportExpenses';
export const FOOD_EXPENSES = 'foodExpenses';
export const OTHER_EXPENSES = 'otherExpenses';
export const SORT_CODE = 'sortCode';
export const ACCOUNT_NUMBER = 'accountNumber';
export const DEBIT_CARD_TYPE = 'debitCardType';
export const BANK_NAME = 'bankName';
export const CCJ = 'ccj';
export const CURRENTLY_IVA = 'currentlyIva';
export const CURRENTLY_BANKRUPT = 'currentlyBankrupt';
export const DEFAULT = 'default';
export const CLIENT_IP = 'clientIpAddress';
