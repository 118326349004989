import ls from 'localstorage-ttl';

const LOCAL_STORAGE_FORM = 'loanFinderFormV1';
const CUST_ID = 'custId';
const SAVE_FORM_TTL = 60 * 1000 * 60; // 60 mins
const SAVE_CUST_TTL = Math.pow(3.154, 10); // 1 year

export function saveForm(formData) {
  try {
    ls.set(LOCAL_STORAGE_FORM, formData, SAVE_FORM_TTL);
  } catch (e) {
    console.log(e);
  }
}

export function getForm() {
  try {
    return ls.get(LOCAL_STORAGE_FORM);
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function saveCustId(id) {
  try {
    ls.set(CUST_ID, id, SAVE_CUST_TTL);
  } catch (e) {
    console.log(e);
  }
}

export function loadCustId() {
  try {
    return ls.get(CUST_ID);
  } catch (e) {
    console.log(e);
  }
}
