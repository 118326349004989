import { compose } from 'rambda';
import sortcodeToBank from './../../helpers/sortcodeToBank';
import * as s from './../../constants/stageNames';
import * as e from './enums';
import * as f from './../../constants/fieldNames';
import {
  setHidden,
  mergeHidden,
  mergeData,
  setValid,
  setData,
} from './../../redux/update';
import anyMatch from '../../util/anyMatch';
import { getDates } from './dates';

export default {
  [s.LOAN_AMOUNT_STAGE](state) {
    let adjustedLoanTerm = state.data[f.LOAN_TERM];
    const loanAmount = state.data[f.LOAN_AMOUNT];

    if (loanAmount > 5000 && adjustedLoanTerm < 24) adjustedLoanTerm = 24;
    if (loanAmount < 1000 && adjustedLoanTerm > 6) adjustedLoanTerm = 6;
    if (loanAmount >= 1000 && adjustedLoanTerm < 12) adjustedLoanTerm = 12;
    if (loanAmount <= 5000 && adjustedLoanTerm > 36) adjustedLoanTerm = 36;

    return compose(
      setData([f.LOAN_TERM], adjustedLoanTerm),
      setValid([f.LOAN_TERM], true)
    )(state);
  },
  [s.LOAN_PURPOSE_STAGE](state) {
    const showDetailStage = state.data[f.LOAN_PURPOSE] === e.LP_OTHER;

    return setHidden(s.LOAN_PURPOSE_DETAIL_STAGE, !showDetailStage, state);
  },
  [s.RESIDENTIAL_STATUS_STAGE](state) {
    const showPropertyValueStage =
      state.data[f.RESIDENTIAL_STATUS] === e.RS_HOMEOWNER;

    return setHidden(s.PROPERTY_VALUE_STAGE, !showPropertyValueStage, state);
  },
  [s.TIME_AT_CURRENT_ADDRESS_STAGE](state) {
    const ta = state.data[f.TIME_AT_CURRENT_ADDRESS];

    let hidePrevAddressStages = anyMatch(
      ta,
      e.TA_5_PLUS_YEARS,
      e.TA_4_5_YEARS,
      e.TA_3_4_YEARS
    );

    return mergeHidden(
      {
        [s.PREVIOUS_ADDRESS_STAGE]: hidePrevAddressStages,
        [s.PREVIOUS_RESIDENTIAL_STATUS_STAGE]: hidePrevAddressStages,
        [s.TIME_AT_PREVIOUS_ADDRESS_STAGE]: hidePrevAddressStages,
      },
      state
    );
  },
  [s.EMPLOYMENT_STATUS_STAGE](state) {
    const es = state.data[f.EMPLOYMENT_STATUS];

    const hide = !anyMatch(
      es,
      e.ES_FULL_TIME,
      e.ES_PART_TIME,
      e.ES_TEMPORARY_OR_CONTRACT
    );

    const hideGrossSalary = es !== e.ES_SELF_EMPLOYED;

    return mergeHidden(
      {
        [s.EMPLOYER_INDUSTRY_STAGE]: hide,
        [s.GROSS_SALARY_STAGE]: hideGrossSalary,
        [s.EMPLOYER_NAME_STAGE]: hide,
        [s.EMPLOYER_INDUSTRY_STAGE]: hide,
        [s.JOB_TITLE_STAGE]: hide,
        [s.TIME_AT_CURRENT_JOB_STAGE]: hide,
      },
      state
    );
  },
  [s.SALARY_FREQUENCY_STAGE](state) {
    const now = state.dates.now;

    let next = state.data[f.NEXT_PAY_DATE];
    let following = state.data[f.FOLLOWING_PAY_DATE];
    let hideNextPayDateStage = false;

    const sf = state.data[f.SALARY_FREQUENCY];

    if (
      anyMatch(
        sf,
        e.SF_MONTHLY_LAST_THURSDAY,
        e.SF_MONTHLY_LAST_FRIDAY,
        e.SF_MONTHLY_LAST_DAY,
        e.SF_MONTHLY_LAST_WORKING_DAY
      )
    ) {
      const dates = getDates(now, sf);
      next = dates[0];
      following = dates[1];
      hideNextPayDateStage = true;
    }

    return compose(
      setHidden([s.NEXT_PAY_DATE_STAGE], hideNextPayDateStage),
      mergeData({
        [f.NEXT_PAY_DATE]: next,
        [f.FOLLOWING_PAY_DATE]: following,
      })
    )(state);
  },
  [s.EXPECTING_INCOME_CHANGE_STAGE](state) {
    const hide = state.data[f.EXPECTING_CHANGE_TO_INCOME] === false;

    return setHidden(s.REASONS_FOR_CHANGE_TO_INCOME_STAGE, hide, state);
  },

  [s.REASONS_FOR_CHANGE_TO_INCOME_STAGE](state) {
    const hide = state.data[f.REASON_FOR_CHANGE_TO_INCOME] !== e.IC_OTHER;

    return setHidden(s.REASONS_FOR_CHANGE_TO_INCOME_DETAIL_STAGE, hide, state);
  },

  [s.DEFAULTED_STAGE](state) {
    const hide = state.data[f.DEFAULT] === false;
    return mergeHidden({
      [s.CCJ_STAGE]: hide,
      [s.IVA_STAGE]: hide,
      [s.BANKRUPT_STAGE]: hide,
    })(state);
  },
  [s.BANK_DETAILS_STAGE](state) {
    const [bank, cardType] = sortcodeToBank(state.data[f.SORT_CODE]);

    return mergeData({
      [f.BANK_NAME]: bank,
      [f.DEBIT_CARD_TYPE]: cardType,
    })(state);
  },
};
