import update from 'immutability-helper';
import * as f from '../../constants/fieldNames';

export function setAddress(index, prev, state) {
  let a1 = f.ADDRESS_1;
  let a2 = f.ADDRESS_2;
  let a3 = f.ADDRESS_3;
  let town = f.TOWN_OR_CITY;
  let county = f.COUNTY;
  let street = f.STREET;
  let houseNumber = f.HOUSE_NUMBER;
  let flatNameOrNumber = f.FLAT_NAME_OR_NUMBER;
  let houseOrBuildingName = f.HOUSE_OR_BUILDING_NAME;

  if (prev) {
    a1 = f.PREVIOUS_ADDRESS_1;
    a2 = f.PREVIOUS_ADDRESS_2;
    a3 = f.PREVIOUS_ADDRESS_3;
    town = f.PREVIOUS_TOWN_OR_CITY;
    county = f.PREVIOUS_COUNTY;
    street = f.PREVIOUS_STREET;
    houseNumber = f.PREVIOUS_HOUSE_NUMBER;
    flatNameOrNumber = f.PREVIOUS_FLAT_NAME_OR_NUMBER;
    houseOrBuildingName = f.PREVIOUS_HOUSE_OR_BUILDING_NAME;
  }

  const active = prev ? 'prev' : 'current';
  if (state.addresses[active].data.length !== 0) {
    // TODO UNPICK AND SET DEFAULTS
    const a = state.addresses[active].data[index];

    return update(state, {
      addresses: {
        [active]: {
          hasSelected: { $set: true },
        },
      },
      data: {
        $merge: {
          [a1]: a.address1,
          [a2]: a.address2,
          [a3]: a.address3,
          [town]: a.town,
          [county]: a.county,
          [street]: a.street,
          [houseNumber]: a.houseNumber,
          [flatNameOrNumber]: a.flatNameOrNumber,
          [houseOrBuildingName]: a.houseOrBuildingName,
        },
      },
    });
  }
}
