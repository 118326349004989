import moment from 'moment';
import * as e from './enums';

export function getDates(now, salaryFrequency) {
  const payDates = [];

  let startingDate = now;

  // get 3 months of pay dates starting from now
  for (let i = 0; i < 3; i += 1) {
    if (salaryFrequency === e.SF_MONTHLY_LAST_THURSDAY) {
      payDates.push(getLastWeekDayOfMonth(startingDate, 'thursday'));
    }

    if (salaryFrequency === e.SF_MONTHLY_LAST_FRIDAY) {
      payDates.push(getLastWeekDayOfMonth(startingDate, 'friday'));
    }

    if (salaryFrequency === e.SF_MONTHLY_LAST_WORKING_DAY) {
      payDates.push(getLastWeekDayOfMonth(startingDate));
    }

    if (salaryFrequency === e.SF_MONTHLY_LAST_DAY) {
      payDates.push(
        moment(startingDate)
          .endOf('month')
          .hour(12)
          .toISOString()
      );
    }

    // go forward a month
    startingDate = moment(startingDate).add(1, 'month');
  }

  // remove any dates that are equal to or less than today
  return payDates.filter(d => {
    return !moment(d).isSame(now, 'day');
  });
}

/**
 * day is a String day eg. 'friday' or 'thursday' and blank for any weekday
 * now is ISO timestamp
 *
 * returns ISO timestamp
 */
export function getLastWeekDayOfMonth(now, day = '') {
  // default to any week day
  let validDays = [1, 2, 3, 4, 5]; // mon - fri

  if (day === 'thursday') validDays = [4];
  if (day === 'friday') validDays = [5];

  let testDay = moment(now).endOf('month');

  while (true) {
    if (validDays.indexOf(testDay.day()) >= 0) {
      return testDay.hour(12).toISOString();
    }
    testDay = testDay.subtract(1, 'day');
  }
}
