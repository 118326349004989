import queryString from 'query-string';
import * as f from '../constants/fieldNames';
import * as s from '../constants/stageNames';
import * as d from '../constants/defaults';
import now from '../util/now';
import { POWERED_BY_AFFILIATE_REF } from '../constants/api';

// Bit of a hack to get the gclid that google send to the front end from adwords.
let G_CLID;
if (typeof window !== `undefined`) {
  G_CLID = queryString.parse(window.location.search).gclid || POWERED_BY_AFFILIATE_REF; 
}

const formData = {
  [f.AFFILIATE_REF]: G_CLID || POWERED_BY_AFFILIATE_REF,
  [f.LOAN_AMOUNT]: d.LOAN_AMOUNT,
  [f.LOAN_TERM]: d.LOAN_TERM,
  [f.LOAN_PURPOSE]: '',
  [f.LOAN_PURPOSE_DETAIL]: '',
  [f.TITLE]: '',
  [f.FIRST_NAME]: '',
  [f.LAST_NAME]: '',
  [f.DOB]: {
    dd: '',
    mm: '',
    yyyy: '',
  },
  [f.EMAIL_ADDRESS]: '',
  [f.HOME_PHONE]: '',
  [f.MOBILE_PHONE]: '',
  [f.WORK_PHONE]: '',
  [f.MARITAL_STATUS]: '',
  [f.NUMBER_OF_DEPENDANTS]: '',
  [f.OTHER_NUMBER_OF_DEPENDANTS]: '',
  [f.POSTCODE]: '',
  [f.ADDRESS_1]: '',
  [f.ADDRESS_2]: '',
  [f.ADDRESS_3]: '',
  [f.TOWN_OR_CITY]: '',
  [f.COUNTY]: '',
  [f.STREET]: '',
  [f.FLAT_NAME_OR_NUMBER]: '',
  [f.HOUSE_OR_BUILDING_NAME]: '',
  [f.RESIDENTIAL_STATUS]: '',
  [f.PROPERTY_VALUE]: '',
  [f.TIME_AT_CURRENT_ADDRESS]: '',
  [f.PREVIOUS_POSTCODE]: '',
  [f.PREVIOUS_ADDRESS_1]: '',
  [f.PREVIOUS_ADDRESS_2]: '',
  [f.PREVIOUS_ADDRESS_3]: '',
  [f.PREVIOUS_TOWN_OR_CITY]: '',
  [f.PREVIOUS_COUNTY]: '',
  [f.PREVIOUS_FLAT_NAME_OR_NUMBER]: '',
  [f.PREVIOUS_HOUSE_NUMBER]: '',
  [f.PREVIOUS_HOUSE_OR_BUILDING_NAME]: '',
  [f.PREVIOUS_STREET]: '',
  [f.PREVIOUS_RESIDENTIAL_STATUS]: '',
  [f.TIME_AT_PREVIOUS_ADDRESS]: '',
  [f.EMPLOYMENT_STATUS]: '',
  [f.GROSS_SALARY]: '',
  [f.COMPANY_NAME]: '',
  [f.EMPLOYER_INDUSTRY]: '',
  [f.OCCUPATION]: '',
  [f.TIME_AT_CURRENT_JOB]: '',
  [f.SALARY_FREQUENCY]: '',
  [f.NEXT_PAY_DATE]: '',
  [f.FOLLOWING_PAY_DATE]: '',
  [f.MONTHLY_TAKE_HOME_PAY]: '',
  [f.EXPECTING_CHANGE_TO_INCOME]: null,
  [f.REASON_FOR_CHANGE_TO_INCOME]: '',
  [f.REASON_FOR_CHANGE_TO_INCOME_DETAIL]: '',
  [f.OTHER_MONTHLY_INCOME]: '',
  [f.INCOME_PAYMENT_TYPE]: '',
  [f.MONTHLY_MORTGAGE_OR_RENT]: '',
  [f.TOTAL_OUTSTANDING_DEBT]: '',
  [f.DEBT_EXPENSES]: '',
  [f.UTILITIES_EXPENSES]: '',
  [f.TRANSPORT_EXPENSES]: '',
  [f.FOOD_EXPENSES]: '',
  [f.OTHER_EXPENSES]: '',
  [f.SORT_CODE]: '',
  [f.ACCOUNT_NUMBER]: '',
  [f.DEBIT_CARD_TYPE]: '',
  [f.BANK_NAME]: '',
  [f.CCJ]: null,
  [f.CURRENTLY_IVA]: null,
  [f.CURRENTLY_BANKRUPT]: null,
  [f.DEFAULT]: null,
};

const valid = {};
const errors = {};
const warnings = {};

Object.keys(formData).forEach(field => {
  valid[field] = false;
  errors[field] = '';
  warnings[field] = '';
});

const submitted = {};

Object.keys(s).forEach(key => {
  submitted[s[key]] = false;
});

export const defaultAddressState = {
  busy: false,
  hasRun: false,
  hasSelected: false,
  error: '',
  data: [],
};

const state = {
  dates: {
    now: now(),
  },
  ui: {
    activeTimerStage: s.LOAN_AMOUNT_STAGE,
    activeStage: s.LOAN_AMOUNT_STAGE,
    direction: 'forward',
    transitioning: false,
    activeModal: null,
    showTheatre: true,
    showTheatreQuestions: true,
    theatreQuestionAnswered: false,
    showCountDown: true,
    error: false,
    busy: false,
    hideAffordabilityCheck: true,
    affordabilityModalActiveField: '',
  },
  addresses: {
    current: { ...defaultAddressState },
    prev: { ...defaultAddressState },
  },
  hidden: {
    [s.LOAN_PURPOSE_DETAIL_STAGE]: true,
    [s.PROPERTY_VALUE_STAGE]: true,
    [s.PREVIOUS_ADDRESS_STAGE]: true,
    [s.PREVIOUS_RESIDENTIAL_STATUS_STAGE]: true,
    [s.JOB_TITLE_STAGE]: true,
    [s.NEXT_PAY_DATE_STAGE]: true,
    [s.REASONS_FOR_CHANGE_TO_INCOME_DETAIL_STAGE]: true,
  },
  data: formData,
  submitted,
  results: [],
  valid,
  errors,
  warnings,
};

export default state;
