import sortcodeToBank from './../../../helpers/sortcodeToBank';

export function validateSortCodeLength(sc) {
  const re = /^(\d){6}$/;
  return re.test(sc);
}

export function validateSortCodeHasBank(sc) {
  return sortcodeToBank(sc)[0] !== '';
}

export function validateBankAccountNumber(accountNumber) {
  const re = /^(\d){8}$/;
  return re.test(accountNumber);
}
