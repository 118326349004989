import stageFields from './stageFields';
import fieldValidation from './validation';

export default function validateStage(state) {
  const errors = {};
  const valid = {};

  const { activeStage } = state.ui;
  const { data } = state;

  const currentFields = stageFields[activeStage];

  // check all fields for stage are valid
  let allValid = true;

  currentFields.forEach(field => {
    const [ok, err] = fieldValidation[field](data[field], state);

    valid[field] = ok;
    errors[field] = err;

    if (!ok) allValid = false;
  });

  return [allValid, { errors, valid }];
}
