import { navigate } from 'gatsby';
import * as f from './../../constants/fieldNames';
import * as ev from './../../constants/analyticsEvents';
// import * as a from './../../constants/api';
import { saveCustId, loadCustId } from './../form/saveLoad';
import ReactGA from 'react-ga';
import axios from 'axios';

function generateHash() {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
}

export function submitForm(formData, settings) {
  navigate('/redirect'); // - go to redirect page and wait for a response from PBL

  let custId = loadCustId(); // look in local to see if this cust has an id.

  if (!custId) {
    // No id in local so lets make one
    const freshCustId = generateHash();
    saveCustId(freshCustId);
    custId = freshCustId;
  }

  const reqdata = formData;

  axios
    .post(process.env.FORM_POST_URL, reqdata, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      ReactGA.event({
        category: ev.FORM_CATEGORY,
        action: ev.FORM_ACTION_API_RESPONSE,
        label: `response.data: ${response.data}`,
      });

      if (response.data !== 'SYSTEM_ERROR') {
        window.location = response.data;
      } else {
        navigate('/error?type=poweredBy_bad_response');
      }
    })
    .catch(err => {
      navigate('/error?type=poweredBy_response_error');
      console.log(err);
    });

  ReactGA.event({
    category: ev.FORM_CATEGORY,
    action: ev.FORM_ACTION_SUBMIT,
    label: `la: ${formData[f.LOAN_AMOUNT]} lt: ${formData[f.LOAN_TERM]}`,
  });
}
