export const T_MR = 'Mr';
export const T_MRS = 'Mrs';
export const T_MISS = 'Miss';
export const T_MS = 'Ms';

export const LP_CAR = 'CarOther';
export const LP_HOME = 'HomeImprovements';
export const LP_DEBTS = 'DebtConsolidation';
export const LP_BILLS = 'PayDayLoan';
export const LP_OTHER = 'Other';

export const MS_SINGLE = 'Single';
export const MS_MARRIED = 'Married';
export const MS_COMMON_LAW = 'CommonLaw';
export const MS_DIVORCED = 'Divorced';
export const MS_WIDOWED = 'Widowed';

export const ND_ZERO = '0';
export const ND_ONE = '1';
export const ND_TWO = '2';
export const ND_THREE = '3';
export const ND_FOUR = '4';
export const ND_FOUR_PLUS = '4+';

export const RS_HOMEOWNER = 'Homeowner';
export const RS_TENANT = 'Tenant';
export const RS_FAMILY = 'LivingWithFamily';

export const TA_UNDER_1_YEAR = 'lessThan1Year';
export const TA_1_2_YEARS = '1-2Years';
export const TA_2_3_YEARS = '2-3Years';
export const TA_3_4_YEARS = '3-4Years';
export const TA_4_5_YEARS = '4-5Years';
export const TA_5_PLUS_YEARS = '5+Years';

export const ES_FULL_TIME = 'FullTime';
export const ES_PART_TIME = 'PartTime';
export const ES_TEMPORARY_OR_CONTRACT = 'TemporaryOrContract';
export const ES_SELF_EMPLOYED = 'SelfEmployed';
export const ES_EDUCATION = 'Education';
export const ES_RETIRED = 'Retired';
export const ES_BENEFITS = 'BenefitsJSA';

export const EI_HEALTH = 'Health';
export const EI_CONSTRUCTION_MANUFACTURING = 'ConstructionManufacturing';
export const EI_BANKING_INSURANCE = 'BankingInsurance';
export const EI_EDUCATION = 'Education';
export const EI_CIVIL_SERVICE = 'CivilService';
export const EI_SUPERMARKET_RETAIL = 'SupermarketRetail';
export const EI_HOTEL_RESTAURANT_AND_LEISURE = 'HotelRestaurantAndLeisure';
export const EI_UTILITIES_TELECOM = 'UtilitiesTelecom';
export const EI_MILITARY = 'Military';
export const EI_OTHER_OFFICE_BASED = 'OtherOfficeBased';

export const ET_LESS_THAN_3_MONTHS = 'lessThan3Months';
export const ET_3_6_MONTHS = '3-6Months';
export const ET_6_12_MONTHS = '6-12Months';
export const ET_1_2_YEARS = '1-2Years';
export const ET_2_3_YEARS = '2-3Years';
export const ET_3_PLUS_YEARS = '3+Years';

export const PT_CASH = 'Cash';
export const PT_DIRECT_DEPOSIT = 'DirectDebitUK';

export const SF_MONTHLY_LAST_DAY = 'MonthlyLastDay';
export const SF_MONTHLY_LAST_WORKING_DAY = 'MonthlyLastWorkingDay';
export const SF_MONTHLY_LAST_THURSDAY = 'MonthlyLastThursday';
export const SF_MONTHLY_LAST_FRIDAY = 'MonthlyLastFriday';
export const SF_FOUR_WEEKLY = 'FourWeekly';
export const SF_TWICE_MONTHLY = 'TwiceMonthly';
export const SF_FORTNIGHTLY = 'Fortnightly';
export const SF_WEEKLY = 'Weekly';
export const SF_MONTHLY_SPECIFIC_DATE = 'MonthlySpecificDate';

export const IC_RETIREMENT = 'Retirement';
export const IC_REDUNDANCY = 'Redundacy';
export const IC_END_OF_FIXED_TERM_CONTRACT = 'EndOfFixedTermContract';
export const IC_OTHER = 'Other';
