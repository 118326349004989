import moment from 'moment';
import { isString, isBoolean, isNumber } from 'lodash';
import * as f from '../../constants/fieldNames';
import * as e from './enums';
import validateEmail from './validators/email';
import ukPhoneNumber from './validators/ukPhoneNumber';
import ukMobileNumber from './validators/ukMobileNumber';
import dateOfBirth from './validators/dateOfBirth';
import validatePostcode from './validators/postcode';
import addressValid from './validators/address';
import {
  validateSortCodeLength,
  validateSortCodeHasBank,
  validateBankAccountNumber,
} from './validators/bankDetails';
import okErr from '../../util/okErr';

export function blank(val) {
  // any number or boolean will do
  if (isBoolean(val)) return false;
  if (isNumber(val)) return false;

  // no blank strings
  if (isString(val) && val.replace(/\s/g, '') === '') return true;

  // no null or undefined
  if (!val) return true;

  return false;
}

// used for checking strings, booleans and numbers
function notBlank(error) {
  return fieldValue => {
    if (blank(fieldValue)) {
      return okErr(error);
    }

    return okErr();
  };
}

function checkWithFn(validationFunction, error) {
  return value => {
    if (validationFunction(value)) {
      return okErr();
    }

    return okErr(error);
  };
}

// function alwaysOk() {
//   return okErr();
// }

function addressOk(a1, a2, a3, town, postcode) {
  const err = 'Please select your address';

  return (value, state) => {
    const d = state.data;
    const ok = addressValid(d[a1], d[a2], d[a3], d[town], d[postcode]);

    if (ok) return okErr();
    return okErr(err);
  };
}

function validateMonthlyRentMortgage(value, state) {
  const formData = state.data;
  /**
   *  Fail validation for customers who are tenants and enter 0 for their rent.
   **/
  if (
    formData[f.RESIDENTIAL_STATUS] !== e.RS_FAMILY &&
    value < 10 &&
    formData[f.RESIDENTIAL_STATUS] !== e.RS_HOMEOWNER
  ) {
    return 'If you are a tenant your rent cannont be £0 per month.';
  } else if (blank(value)) {
    return 'Please tell us your rent/mortgage. If you pay nothing please enter 0.';
  } else {
    return false;
  }
}

function validateFoodExpenses(value, state) {
  const formData = state.data;
  /**
   *  Fail validation for customers who are not living with parents and their food costs are 0.
   **/
  if (formData[f.RESIDENTIAL_STATUS] !== e.RS_FAMILY && value < 10) {
    return 'If you are a tenant/homeowner your share of food bills cannot be £0 per month.';
  } else if (blank(value)) {
    return 'Please tell us what you spend on food';
  } else {
    return false;
  }
}

const currentAddressOk = addressOk(
  f.ADDRESS_1,
  f.ADDRESS_2,
  f.ADDRESS_3,
  f.TOWN_OR_CITY,
  f.POSTCODE
);

const prevAddressOk = addressOk(
  f.PREVIOUS_ADDRESS_1,
  f.PREVIOUS_ADDRESS_2,
  f.PREVIOUS_ADDRESS_3,
  f.PREVIOUS_TOWN_OR_CITY,
  f.PREVIOUS_POSTCODE
);

export default {
  [f.LOAN_AMOUNT]: function(la) {
    // TODO - move to settings/defauls
    if (la !== 0) {
      return okErr();
    }
    if (la === 0) return okErr('Cannot be 0');

    return okErr('How much would you like to borrow?');
  },
  [f.LOAN_TERM]: function(loanTerm, state) {
    // TODO - move to settings/defauls
    const loanAmount = state.data[f.LOAN_AMOUNT];

    if (loanTerm === 0) return okErr('Cannot be 0');

    let err;

    let loanTermMin = 12;
    let loanTermMax = 36;

    if (loanAmount > 5000) {
      loanTermMin = 24;
      loanTermMax = 60;
    }

    if (loanAmount < 1000) {
      loanTermMin = 3;
      loanTermMax = 6;
    }

    if (loanTerm < loanTermMin || loanTerm > loanTermMax) {
      err = `Enter a repayment period between ${loanTermMin} and ${loanTermMax} months`;
    }

    return okErr(err);
  },
  [f.LOAN_PURPOSE]: notBlank('Please tell us what you need the loan for'),
  [f.LOAN_PURPOSE_DETAIL]: notBlank(
    'Please give us some more detail on what you need the loan for'
  ),
  [f.TITLE]: notBlank('Your title is required'),
  [f.FIRST_NAME]: notBlank('Tell us your first name'),
  [f.LAST_NAME]: notBlank('Tell us your last name'),
  [f.DOB]: function(dob, state) {
    return okErr(dateOfBirth(dob, state.dates.now));
  },
  [f.EMAIL_ADDRESS]: checkWithFn(
    validateEmail,
    'Please enter a valid email address'
  ),
  [f.HOME_PHONE]: checkWithFn(
    ukPhoneNumber,
    'Please enter a valid UK phone number'
  ),
  [f.MOBILE_PHONE]: checkWithFn(
    ukMobileNumber,
    'Please enter a valid UK mobile number'
  ),
  [f.WORK_PHONE]: checkWithFn(
    ukPhoneNumber,
    'Please enter a valid UK phone number'
  ),
  [f.MARITAL_STATUS]: notBlank('Marital status is required'),
  [f.NUMBER_OF_DEPENDANTS]: notBlank('Number of dependants is required'),
  [f.OTHER_NUMBER_OF_DEPENDANTS]: value => {
    if (value < 1 || value > 99) {
      return okErr('Number of dependants is required');
    }

    return okErr();
  },
  [f.POSTCODE]: checkWithFn(validatePostcode, 'You need to enter a postcode.'),
  [f.ADDRESS_1]: currentAddressOk,
  [f.ADDRESS_2]: currentAddressOk,
  [f.ADDRESS_3]: currentAddressOk,
  [f.TOWN_OR_CITY]: currentAddressOk,
  [f.COUNTY]: currentAddressOk,
  [f.RESIDENTIAL_STATUS]: notBlank('Residential status is required'),
  [f.TIME_AT_CURRENT_ADDRESS]: notBlank(
    'Please tell us how long you have lived at your address.'
  ),
  [f.PROPERTY_VALUE]: value => {
    if (value < 10000) {
      return okErr('Your property value is required');
    }

    return okErr();
  },
  [f.PREVIOUS_POSTCODE]: checkWithFn(
    validatePostcode,
    'You need to enter a postcode.'
  ),
  [f.PREVIOUS_ADDRESS_1]: prevAddressOk,
  [f.PREVIOUS_ADDRESS_2]: prevAddressOk,
  [f.PREVIOUS_ADDRESS_3]: prevAddressOk,
  [f.PREVIOUS_TOWN_OR_CITY]: prevAddressOk,
  [f.PREVIOUS_COUNTY]: prevAddressOk,
  [f.PREVIOUS_RESIDENTIAL_STATUS]: notBlank('Residential status is required'),
  [f.TIME_AT_PREVIOUS_ADDRESS]: notBlank(
    'Please tell us how long you have lived at your previous address.'
  ),
  [f.EMPLOYMENT_STATUS]: notBlank('Tell us your employment status.'),
  [f.GROSS_SALARY]: value => {
    if (value < 5000) {
      return okErr('Please tell us your yearly gross income before tax');
    }

    return okErr();
  },
  [f.EMPLOYER_INDUSTRY]: notBlank('Tell us your employment industry.'),
  [f.COMPANY_NAME]: notBlank('Tell us your employers name.'),
  [f.OCCUPATION]: notBlank('Tell us your job title.'),
  [f.TIME_AT_CURRENT_JOB]: notBlank('Time in current job is required.'),
  [f.SALARY_FREQUENCY]: notBlank('Please tell us when you get your income.'),
  [f.NEXT_PAY_DATE]: (value, state) => {
    const today = moment(state.dates.now);

    if (blank(value)) {
      return okErr('This date is required.');
    }

    const npd = moment(value);

    if (!npd.isValid()) {
      return okErr('This date is required.');
    }

    if (npd.isSame(today, 'day')) {
      return okErr('Next pay date can not be today');
    }

    if (npd.isBefore(today)) {
      return okErr('Next pay date can not be in the past');
    }

    return okErr();
  },
  [f.FOLLOWING_PAY_DATE]: (value, state) => {
    const today = moment(state.dates.now);

    if (blank(value)) {
      return okErr('This date is required.');
    }

    const npd = moment(state.data[f.NEXT_PAY_DATE]);
    const fpd = moment(value);

    if (!fpd.isValid()) {
      return okErr('This date is required.');
    }

    if (fpd.isSame(today, 'day')) {
      return okErr('Following pay date can not be today');
    }

    if (fpd.isBefore(today)) {
      return okErr('Following pay date can not be in the past');
    }

    const dayDiff = fpd.diff(npd, 'days');

    if (fpd.isSame(npd, 'day')) {
      return okErr('Next pay date and following pay date can not be the same');
    }

    if (fpd.isBefore(npd)) {
      return okErr('Following pay date cannot be before next pay date');
    }

    if (dayDiff < 6) {
      return okErr('Your pay dates are too close together');
    }

    if (dayDiff > 35) {
      return okErr('Your pay dates are too far apart');
    }

    return okErr();
  },
  [f.MONTHLY_TAKE_HOME_PAY]: value => {
    if (value < 100) {
      return okErr('Please tell us your income.');
    }

    return okErr();
  },
  [f.EXPECTING_CHANGE_TO_INCOME]: (value, state) => {
    const loanTerm = state.data[f.LOAN_TERM];

    if (!isBoolean(value)) {
      return okErr(
        `Tell us if you are expecting a change in your income in the next ${loanTerm} months`
      );
    }

    return okErr();
  },
  [f.REASON_FOR_CHANGE_TO_INCOME]: notBlank(
    'Tell us why your income will change during this time.'
  ),
  [f.REASON_FOR_CHANGE_TO_INCOME_DETAIL]: notBlank(
    'Please give us some more detail on why your income is changing.'
  ),
  [f.INCOME_PAYMENT_TYPE]: notBlank('Tell us how you get paid.'),
  [f.OTHER_MONTHLY_INCOME]: notBlank(
    'If you have no other income please enter 0.'
  ), // write a check fields function.
  [f.MONTHLY_MORTGAGE_OR_RENT]: (value, state) => {
    const message = validateMonthlyRentMortgage(value, state);

    if (message) {
      return okErr(message);
    }

    return okErr();
  },
  [f.TOTAL_OUTSTANDING_DEBT]: notBlank(
    'If you have no outstanding debt please enter 0. Please make sure to include any outstanding credit card balance.'
  ),
  [f.DEBT_EXPENSES]: notBlank('If you have no debt payments please enter 0.'),
  [f.UTILITIES_EXPENSES]: notBlank(
    'Please tell us what your share of your bills is.'
  ),
  [f.TRANSPORT_EXPENSES]: notBlank(
    'Please tell us what you spend on transport.'
  ),
  [f.FOOD_EXPENSES]: (value, state) => {
    const message = validateFoodExpenses(value, state);

    if (message) {
      return okErr(message);
    }

    return okErr();
  },
  [f.OTHER_EXPENSES]: notBlank(
    'Please enter any other monthly costs. If none enter 0.'
  ),
  [f.CCJ]: notBlank('Please tell us if you have any CCJs.'),
  [f.CURRENTLY_IVA]: notBlank('Please tell us if you have an IVA.'),
  [f.CURRENTLY_BANKRUPT]: notBlank(
    'Please tell us if you have ever been declared bankrupt.'
  ),
  [f.DEFAULT]: notBlank('Please tell us if you have ever defaulted on a loan.'),
  [f.SORT_CODE]: value => {
    if (!validateSortCodeLength(value))
      return okErr('Please enter a valid sort code with no spaces e.g. 123456');
    if (!validateSortCodeHasBank(value))
      return okErr("We can't find a bank with that sort code");
    return okErr();
  },
  [f.ACCOUNT_NUMBER]: checkWithFn(
    validateBankAccountNumber,
    'Please enter a valid Uk bank account number eg 87654321'
  ),
};
