import moment from 'moment';

export default function getDobErrorMessage(dob, now) {
  const digitsOnly = /^\d+$/;

  // we don't want to accept customers under 18 or over 80.
  // we also want to make sure all the date fields are entered and valid
  // and provide guidance on any incorrect input.
  const errorMessages = {
    dayError: 'Enter the day of the month as a number between 1 and 31',
    monthError: 'Enter the month as a number between 1 and 12',
    yearError: 'Enter a year in the format YYYY',
    tooYoung: 'You must be over 18 to apply for a loan',
    tooOld: 'You must be under 80 to apply for a loan',
    defaultError: 'Please enter a valid date',
  };

  const eighteenYearsAgo = moment(now).subtract(18, 'years');
  const eightyYearsAgo = moment(now).subtract(80, 'years');
  const custBirthday = moment(`${dob.yyyy}-${dob.mm}-${dob.dd}`, 'YYYY-MM-DD');

  if (!dob.dd.match(digitsOnly)) {
    return errorMessages.dayError;
  }

  if (!dob.mm.match(digitsOnly)) {
    return errorMessages.monthError;
  }

  if (!dob.yyyy.match(digitsOnly)) {
    return errorMessages.yearError;
  }

  // check all date fields have been entered
  if (
    dob.dd.length < 1 ||
    parseInt(dob.dd, 10) > 31 ||
    parseInt(dob.dd, 10) < 1
  ) {
    return errorMessages.dayError;
  } else if (
    dob.mm.length < 1 ||
    parseInt(dob.mm, 10) > 12 ||
    parseInt(dob.mm, 10) < 1
  ) {
    return errorMessages.monthError;
  } else if (dob.yyyy.length < 4) {
    return errorMessages.yearError;
  }
  // if they have then check the customer is within the correct date thresholds.
  else if (custBirthday.isSameOrAfter(eighteenYearsAgo)) {
    return errorMessages.tooYoung;
  } else if (custBirthday.isSameOrBefore(eightyYearsAgo)) {
    return errorMessages.tooOld;
  }
}
