import * as s from '../../constants/stageNames';
import * as f from '../../constants/fieldNames';

export default {
  [s.LOAN_AMOUNT_STAGE]: [f.LOAN_AMOUNT],
  [s.LOAN_TERM_STAGE]: [f.LOAN_TERM],
  [s.LOAN_PURPOSE_STAGE]: [f.LOAN_PURPOSE],
  [s.LOAN_PURPOSE_DETAIL_STAGE]: [f.LOAN_PURPOSE_DETAIL],
  [s.TITLE_STAGE]: [f.TITLE],
  [s.NAME_STAGE]: [f.FIRST_NAME, f.LAST_NAME],
  [s.DOB_STAGE]: [f.DOB],
  [s.EMAIL_STAGE]: [f.EMAIL_ADDRESS],
  [s.CONTACT_NUMBER_STAGE]: [f.HOME_PHONE, f.MOBILE_PHONE, f.WORK_PHONE],
  [s.MARITAL_STATUS_STAGE]: [f.MARITAL_STATUS],
  [s.NUMBER_OF_DEPENDANTS_STAGE]: [f.NUMBER_OF_DEPENDANTS],
  [s.ADDRESS_STAGE]: [
    f.ADDRESS_1,
    f.ADDRESS_2,
    f.ADDRESS_3,
    f.TOWN_OR_CITY,
    f.COUNTY,
    f.POSTCODE,
  ],
  [s.RESIDENTIAL_STATUS_STAGE]: [f.RESIDENTIAL_STATUS],
  [s.PROPERTY_VALUE_STAGE]: [f.PROPERTY_VALUE],
  [s.TIME_AT_CURRENT_ADDRESS_STAGE]: [f.TIME_AT_CURRENT_ADDRESS],
  [s.ADDRESS_STAGE]: [
    f.ADDRESS_1,
    f.ADDRESS_2,
    f.ADDRESS_3,
    f.TOWN_OR_CITY,
    f.COUNTY,
    f.POSTCODE,
  ],
  [s.RESIDENTIAL_STATUS_STAGE]: [f.RESIDENTIAL_STATUS],
  [s.PROPERTY_VALUE_STAGE]: [f.PROPERTY_VALUE],
  [s.TIME_AT_PREVIOUS_ADDRESS_STAGE]: [f.TIME_AT_CURRENT_ADDRESS],
  [s.PREVIOUS_ADDRESS_STAGE]: [
    f.PREVIOUS_ADDRESS_1,
    f.PREVIOUS_ADDRESS_2,
    f.PREVIOUS_ADDRESS_3,
    f.PREVIOUS_TOWN_OR_CITY,
    f.PREVIOUS_COUNTY,
    f.PREVIOUS_POSTCODE,
  ],
  [s.PREVIOUS_RESIDENTIAL_STATUS_STAGE]: [f.PREVIOUS_RESIDENTIAL_STATUS],
  [s.TIME_AT_PREVIOUS_ADDRESS_STAGE]: [f.TIME_AT_PREVIOUS_ADDRESS],
  [s.EMPLOYMENT_STATUS_STAGE]: [f.EMPLOYMENT_STATUS],
  [s.EMPLOYER_INDUSTRY_STAGE]: [f.EMPLOYER_INDUSTRY],
  [s.GROSS_SALARY_STAGE]: [f.GROSS_SALARY],
  [s.EMPLOYER_NAME_STAGE]: [f.COMPANY_NAME],
  [s.JOB_TITLE_STAGE]: [f.OCCUPATION],
  [s.TIME_AT_CURRENT_JOB_STAGE]: [f.TIME_AT_CURRENT_JOB],
  [s.SALARY_FREQUENCY_STAGE]: [f.SALARY_FREQUENCY],
  [s.NEXT_PAY_DATE_STAGE]: [f.NEXT_PAY_DATE, f.FOLLOWING_PAY_DATE],
  [s.MONTHLY_TAKE_HOME_PAY_STAGE]: [f.MONTHLY_TAKE_HOME_PAY],
  [s.INCOME_PAYMENT_TYPE_STAGE]: [f.INCOME_PAYMENT_TYPE],
  [s.EXPECTING_INCOME_CHANGE_STAGE]: [f.EXPECTING_CHANGE_TO_INCOME],
  [s.REASONS_FOR_CHANGE_TO_INCOME_STAGE]: [f.REASON_FOR_CHANGE_TO_INCOME],
  [s.REASONS_FOR_CHANGE_TO_INCOME_DETAIL_STAGE]: [
    f.REASON_FOR_CHANGE_TO_INCOME_DETAIL,
  ],
  [s.OTHER_MONTHLY_INCOME_STAGE]: [f.OTHER_MONTHLY_INCOME],
  [s.MONTHLY_EXPENSES_STAGE]: [
    f.MONTHLY_MORTGAGE_OR_RENT,
    f.UTILITIES_EXPENSES,
  ],
  [s.MONTHLY_EXPENSES_OTHER_STAGE]: [
    f.TRANSPORT_EXPENSES,
    f.FOOD_EXPENSES,
    f.OTHER_EXPENSES,
  ],
  [s.DEBT_EXPENSES_STAGE]: [f.TOTAL_OUTSTANDING_DEBT, f.DEBT_EXPENSES],
  [s.DEFAULTED_STAGE]: [f.DEFAULT],
  [s.CCJ_STAGE]: [f.CCJ],
  [s.IVA_STAGE]: [f.CURRENTLY_IVA],
  [s.BANKRUPT_STAGE]: [f.CURRENTLY_BANKRUPT],
  [s.BANK_DETAILS_STAGE]: [f.ACCOUNT_NUMBER, f.SORT_CODE],
  [s.SUBMIT_STAGE]: [],
};
