export const UPDATE_FORM_FIELD = 'form/update/field';
export const UPDATE_NUMBER_FIELD = 'form/update/number/field';
export const UPDATE_SELECT_FIELD = 'form/update/select/field';
export const UPDATE_SELECT_BOOL_FIELD = 'form/update/selectBool/field';
export const TOGGLE_BOOL_FIELD = 'form/toggle/bool/field';
export const NEXT_STAGE = 'form/stage/next';
export const PREV_STAGE = 'form/stage/prev';
export const SET_STAGE = 'form/stage/set';
export const SET_TIMER_STAGE = 'form/timerStage/set';
export const STAGE_READY = 'form/stage/ready';
export const SUBMIT_FORM = 'form/submit';
export const LOAD_SAVED = 'form/load/saved';
export const PERCENTAGE_COMPLETE = 'form/stage/percentage';
export const SET_DOB_PART = 'setDOBPart/form';
export const REQUEST_ADDRESSES = 'requestAddresses/form';
export const SELECT_ADDRESS = 'selectAddress/form';
