import stageOrder, { getStageIndex } from './stageOrder';

export default function getNextStage(state, activeStage, backwards = false) {
  const { hidden } = state;

  const currentIndex = getStageIndex(activeStage);

  let nextStage;
  let lookAheadCount = 1;
  // used to control direction
  let modifier = 1;

  if (backwards) {
    modifier = -1;
  }

  // 100 is a magic number. This is to avoid using a potentially infinite loop i.e while(true)
  // This allows us to skip any stages that are hidden
  while (lookAheadCount < 100) {
    let nextIndex = currentIndex + lookAheadCount * modifier;

    if (nextIndex < 1) {
      // beginning of form
      return stageOrder[0];
    }

    nextStage = stageOrder[nextIndex];

    if (!nextStage) {
      // end of form. do nothing
      return activeStage;
    }

    if (!hidden[nextStage]) {
      return nextStage;
    }

    lookAheadCount += 1;
  }
}
