import * as R from 'rambda';
import validatePostcode from './postcode';

function notBlank(v) {
  return !R.isNil(v) && v !== '';
}

export default function addressValid(
  address1,
  address2,
  address3,
  town,
  postcode
) {
  const hasValues =
    R.all(notBlank, [town, postcode]) &&
    R.any(notBlank, [address1, address2, address3]);

  return validatePostcode(postcode) && hasValues;
}
