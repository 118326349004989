export const LOAN_AMOUNT_STAGE = 'loanAmountStage';
export const LOAN_TERM_STAGE = 'loanTermStage';
export const LOAN_PURPOSE_STAGE = 'loanPurposeStage';
export const LOAN_PURPOSE_DETAIL_STAGE = 'loanPurposeDetailStage';
export const TITLE_STAGE = 'titleStage';
export const NAME_STAGE = 'nameStage';
export const DOB_STAGE = 'DOBStage';
export const EMAIL_STAGE = 'emailStage';
export const CONTACT_NUMBER_STAGE = 'contactNumberStage';
export const MARITAL_STATUS_STAGE = 'maritalStatusStage';
export const NUMBER_OF_DEPENDANTS_STAGE = 'numberOfDependantsStage';
export const ADDRESS_STAGE = 'addressStage';
export const RESIDENTIAL_STATUS_STAGE = 'residentialStatusStage';
export const PROPERTY_VALUE_STAGE = 'propertyValueStage';
export const TIME_AT_CURRENT_ADDRESS_STAGE = 'timeAtCurrentAddressStage';
export const PREVIOUS_ADDRESS_STAGE = 'previousAddressStage';
export const PREVIOUS_RESIDENTIAL_STATUS_STAGE =
  'previousResidentialStatusStage';
export const TIME_AT_PREVIOUS_ADDRESS_STAGE = 'timeAtPreviousAddressStage';
export const EMPLOYMENT_STATUS_STAGE = 'employmentStatusStage';
export const EMPLOYER_INDUSTRY_STAGE = 'employerIndustryStage';
export const GROSS_SALARY_STAGE = 'grossSalaryStage';
export const EMPLOYER_NAME_STAGE = 'employerNameStage';
export const JOB_TITLE_STAGE = 'jobTitleStage';
export const TIME_AT_CURRENT_JOB_STAGE = 'timeAtCurrentJobStage';
export const SALARY_FREQUENCY_STAGE = 'salaryFrequencyStage';
export const NEXT_PAY_DATE_STAGE = 'nextPayDateStage';
export const MONTHLY_TAKE_HOME_PAY_STAGE = 'monthlyTakeHomePayStage';
export const AFFORDABILITY_STAGE = 'affordabilityStage';
export const INCOME_PAYMENT_TYPE_STAGE = 'incomePaymentTypeStage';
export const EXPECTING_INCOME_CHANGE_STAGE = 'expectingIncomeChangeStage';
export const REASONS_FOR_CHANGE_TO_INCOME_STAGE =
  'reasonsForChangeToIncomeStage';
export const REASONS_FOR_CHANGE_TO_INCOME_DETAIL_STAGE =
  'reasonsForChangeToIncomeDetailStage';
export const OTHER_MONTHLY_INCOME_STAGE = 'otherMonthlyIncomeStage';
export const DEBT_EXPENSES_STAGE = 'debtExpensesStage';
export const MONTHLY_EXPENSES_STAGE = 'monthlyExpensesStage';
export const MONTHLY_EXPENSES_OTHER_STAGE = 'monthlyExpensesOtherStage';
export const DEFAULTED_STAGE = 'defaultedStage';
export const CCJ_STAGE = 'CCJStage';
export const IVA_STAGE = 'IVAStage';
export const BANKRUPT_STAGE = 'bankruptStage';
export const BANK_DETAILS_STAGE = 'bankDetailsStage';
export const SWITCH_AND_AUTOSAVE_STAGE = 'switchAutosaveStage';
export const NOTIFICATIONS_STAGE = 'notificationStage';
export const SUBMIT_STAGE = 'submitStage';
